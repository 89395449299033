<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<v-col :cols="ViewTotal==true ? 4 : 12"><v-switch v-model="ViewTotal" @click="load()" label="Por Lote Prod."></v-switch></v-col>
					<v-col v-if="ViewTotal" cols="8"><s-text label="N° Lote de Producción" v-model="RcfID" @input="inputLote($event)" ></s-text></v-col>
					
					<v-col cols="12" lg="12">
						<v-row>
							<h3 class="mt-3 ml-6">KG ENVIADO A PROCESO</h3>
							<v-spacer> </v-spacer>
							<v-col cols="3" v-if="!filter.TrnIDCal"><s-date v-model="filter.cDateInitialCal" label="Fecha Inicio"></s-date></v-col>
							<v-col cols="3"  v-if="!filter.TrnIDCal"><s-date v-model="filter.cDateFinCal" label="Fecha Fin"></s-date></v-col>
							<v-col cols="3"><s-turn-in-process :TypeArea="5" clearable v-model="filter.TrnIDCal"></s-turn-in-process></v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								lg="6"
								v-for="item in Object.keys(itemsKgCalibrated)"
							>
								<v-row>
									<h3 class="mt-3 ml-6">{{ item }}</h3>
									<v-spacer> </v-spacer>
								</v-row>

								<v-row>
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-col lg="12" class="pt-0">
									<v-list
										v-for="d in itemsKgCalibrated[item]"
									>
										<v-list-item>
											<v-list-item-avatar>
												<v-btn
													small
													elevation="0"
													fab
													dark
													color="info"
												>
													<b
														><h2>
															{{
																d.LppCountParihuela
															}}
														</h2></b
													>
												</v-btn>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title>
													<h1>
														{{
															d.LppWeightAverageLot
														}} <b style="font-family: Courier; font-size: 18px;"> KG</b>
														
													</h1>
													<b style="color: blue"></b>
												</v-list-item-title>

												<v-list-item-subtitle v-if="ViewTotal">
													<b><h2>LP: {{ d.RcfID }} - {{d.PrfYuliano}}</h2></b>
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
													
												</v-list-item-subtitle>
												<v-list-item-subtitle v-if="!ViewTotal">
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-col>
								
							</v-col>

							<v-divider vertical></v-divider>
						</v-row>
					</v-col>

					<!-- <v-col cols="12" lg="6">
						<v-row>
							<h3 class="mt-3 ml-6">MERMAS</h3>
							<v-spacer> </v-spacer>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								v-for="item in Object.keys(itemsKgDecrease)"
							>
								<v-row>
									<h3 class="mt-3 ml-6">{{ item }}</h3>
									<v-spacer> </v-spacer>
								</v-row>

								<v-row>
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-col lg="12" class="pt-0">
									<v-list v-for="d in itemsKgDecrease[item]">
										<v-list-item>
											<v-list-item-avatar>
												<v-btn
													small
													elevation="0"
													fab
													dark
													color="error"
												>
													
												</v-btn>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title>
													<h1>
														{{
															(d.WbbNetWeight - d.LppWeightAverageLot).toFixed(2)
														}}
														
														<b style="font-family: Courier; font-size: 18px;"> KG</b>
													
													</h1>
													<b style="color: blue"></b>
												</v-list-item-title>

												<v-list-item-subtitle v-if="ViewTotal">
													<b><h2>LP: {{ d.RcfID }}</h2></b>
													<b style="text-transform: uppercase;  -webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b><br>
													<b style="text-transform: uppercase;  -webkit-text-stroke: medium">{{d.WbbNetWeight}}</b>
												</v-list-item-subtitle>
												<v-list-item-subtitle v-if="!ViewTotal">
													<b style="text-transform: uppercase; -webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b><br>
													<b style="text-transform: uppercase; ">{{d.WbbNetWeight}}</b>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-col>
							</v-col>
							<v-divider vertical></v-divider>
						</v-row>
					</v-col> -->
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from "../../../services/FrozenProduction/MaturationTunnelPalletService.js"; // usando
	import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue"

	export default {
		components: {sTurnInProcess},
		data() {
			return {
				filter: {},
				itemsKgDecrease: [],
				itemsKgCalibrated: [],
				ViewTotal: true,
				itemsGeneric: [],
				RcfID: null,
			};
		},
		watch: {
			
			'filter.cDateInitialCal'(){
				this.load()
			},
			'filter.cDateFinCal'(){
				this.load()
			},
			'filter.TrnIDCal'(){
				if(this.filter.TrnIDCal > 0){
					this.filter.cDateInitialCal = null
					this.filter.cDateFinCal = null
				}

				this.load()
			},
			

		},
		methods: {
			load() {
				var view;
				if(this.ViewTotal){
					view = 1
				}else{
					view = 0
				}

				_sMaturationTunnelPalletService
					.GetKgMaduration({TrnIDCal: this.filter.TrnIDCal, ViewTotal:view, cDateInitialCal: this.filter.cDateInitialCal, cDateFinCal: this.filter.cDateFinCal, RcfID: this.RcfID}, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {

							let itemsCalibra = resp.data;
							this.itemsKgCalibrated = _.groupBy(
								itemsCalibra,
								"TypeCaliberName"
							);

							

						}
					});

				/* _sLotProdSelPalletSendService
					.GetKgSelectionDecrease({TrnIDCal: this.filter.TrnIDCal, ViewTotal:view, cDateInitialCal: this.filter.cDateInitialCal, cDateFinCal: this.filter.cDateFinCal, RcfID: this.RcfID}, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							let itemsPack = resp.data;
							this.itemsKgDecrease = _.groupBy(
								itemsPack,
								"RcfID"
							);


						}
					}); */
			},
			inputLote(RcfID){
				this.load()
			}
		},
		created() {
			this.load();
			/* this.isSum() */
		},

		
	};
</script>

<style>
</style>